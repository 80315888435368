(function ($) {
  $(document).ready(function () {
    'use strict';

    const projectBox = $('.project-box');
    const works = $('.works');

    projectBox.hover(function () {
      works.css("background-color", $(this).data('bg'));
      works.not(this).each(function () {
        $(this).css("background-color", $(this).data('bg'));
      });
    }, function () {
      works.css("background-color", '');
    });

    new Swiper('.swiper-slider', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    function setAnalytics() {
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-B8CW0MB8Y6';
      $('#google-tag-manager').append(scriptTag);

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-B8CW0MB8Y6');
    }

    // COOKIE MODAL
    const cookieStorageName = 'cookie-consent';
    const cookieStorageDate = 'cookie-consent-date';
    const cookieConsent = localStorage.getItem(cookieStorageName);
    const consentDate = localStorage.getItem(cookieStorageDate);
    const consentStillValid = consentDate ? (Date.now() - consentDate) < (1000 * 60 * 60 * 24 * 30) : false;

    const cookieModal = $('#modal-cookie-consent');
    if (cookieConsent !== 'accepted' || !consentStillValid) {

      cookieModal.modal({
        backdrop: 'static',
        focus: true
      });
    } else {
      setAnalytics();
    }

    $('#cookie-accept').on('click', () => {
      localStorage.setItem(cookieStorageName, 'accepted');
      localStorage.setItem(cookieStorageDate, Date.now());
      cookieModal.modal('hide');
      setAnalytics();
    });

    $('#cookie-decline').on('click', () => {
      localStorage.removeItem(cookieStorageName);
      localStorage.removeItem(cookieStorageDate);
      cookieModal.modal('hide');
    });

    const pageSection = $('.bg-image');
    pageSection.each(function () {
      if ($(this).attr('data-background')) {
        $(this).css('background-image', `url("${$(this).data("background")}")`);
      }
    });


    // HAMBURGER MENU
    $('.hamburger').on('click', function (e) {
      if ($(".navigation-menu").hasClass("active")) {
        $(".hamburger").toggleClass("open");
        $("body").toggleClass("overflow");
        $(".navigation-menu").removeClass("active");
        $(".navigation-menu .inner .menu").css("transition-delay", "0s");
        $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
        $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");
      } else {
        $(".navigation-menu").addClass('active');
        $(".hamburger").toggleClass("open");
        $("body").toggleClass("overflow");
        $(".navigation-menu.active .inner .menu").css("transition-delay", "0.45s");
        $(".navigation-menu.active .inner blockquote").css("transition-delay", "0.50s");
        $(".navigation-menu .bg-layers span").css("transition-delay", "0s");
      }
      $(this).toggleClass("active");
    });


    // PAGE TRANSITION
    $('body a').on('click', function (e) {
      if (typeof $(this).data('fancybox') === 'undefined' && $(this).attr('target') !== '_blank') {
        e.preventDefault();
        const url = this.getAttribute("href");
        if (url.indexOf('#') !== -1) {
          const hash = url.substring(url.indexOf('#'));

          if ($('body ' + hash).length !== 0) {
            $('.transition-overlay').removeClass("active");
            $(".hamburger").toggleClass("open");
            $("body").toggleClass("overflow");
            $(".navigation-menu").removeClass("active");
            $(".navigation-menu .inner ul").css("transition-delay", "0s");
            $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
            $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");

            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 1000);

          }
        } else {
          $('.transition-overlay').toggleClass("active");
          setTimeout(function () {
            window.location = url;
          }, 1000);

        }
      }
    });

    const divs = $('header');
    const navbar = $('.navbar');
    $(window).on('scroll', function () {
      const st = $(this).scrollTop();
      navbar.css({'background': `rgba(0,0,0,${st < 200 ? 0 : 0.8})`});
      divs.css({'opacity': (1 - st / 700)});
      divs.css({'transition-delay': ('0s')});
      divs.css({'transition': ('0.05s ease-in-out')});
    });

  });

  const wow = new WOW({
    animateClass: 'animated',
    offset: 0
  });


  $(window).on('load', function () {
    $("body").addClass("page-loaded");
    wow.init();
  });

  $(document).scroll(function () {
    $('.odometer').each(function () {
      const parent_section_postion = $(this).closest('section').position();
      const parent_section_top = parent_section_postion.top;
      if ($(document).scrollTop() > parent_section_top - 300) {
        if ($(this).data('status') === 'yes') {
          $(this).html($(this).data('count'));
          $(this).data('status', 'no')
        }
      }
    });
  });

})(jQuery);
